$(function () {
  var mobileMenuToggler = $(".js-mobile-menu-toggler");
  var mobileMenu = $(".js-menu");
  var phonesPopupToggler = $(".js-phones-popup-toggler");
  var phonesPopup = $(".js-contact");


  mobileMenuToggler.on('click', function (event) {
    event.preventDefault();
    phonesPopup.slideUp(500);
    var selector = $(this).attr('data-menu');
    $('.' + selector).slideToggle(500);
    $('.' + selector).removeClass('u-mb-20');
    event.stopPropagation();
  });

  phonesPopupToggler.on('click', function (event) {
    event.preventDefault();
    mobileMenu.slideUp(500);
    mobileMenuToggler.removeClass("active");
    var selector = $(this).attr('data-menu');
    $('.' + selector).slideToggle(500);
    event.stopPropagation();
  });

  $(document).on('click', function (event) {
    if ($(event.target).closest(mobileMenu).length) return;
    if ($(event.target).closest(phonesPopup).length) return;
    var width = $(window).width();
    if (width < 992) {
      mobileMenu.slideUp(500);
      mobileMenuToggler.removeClass("active");
      phonesPopup.slideUp(500);
      event.stopPropagation();
    }
  });


  $(window).resize(function () {
    var width = $(window).width();
    if (width > 991 && mobileMenu.is(':hidden')) {
      mobileMenu.removeAttr('style');
    }
  });

});