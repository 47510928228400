$(function () {
    var form = $(".js-form-footer");
    var f_name = $('.js-form-footer-name');
    var f_phone = $('.js-form-footer-phone');
    var f_email = $('.js-form-footer-email');
    var f_message = $('.js-form-footer-message');
    var fields = $('.form__control');
    var f_error = $('.js-error-messege');

    $(function () {
        $.mask.definitions['~'] = "[+-]";
        $('.js-form-footer-phone').mask('+7 (999) 999-99-99');
    });

    form.validate({
        rules: {
            name: {
                required: true,
                minlength: 3
            },
            phone: {
                required: true
            },
            email: {
                email: true
            }
        },
        messages: {
            name: {
                required: 'Вы забыли написать имя',
                minlength: 'Вы не дописали имя'
            },
            phone: {
                required: 'Вы забыли написать телефон'

            },
            email: {
                email: 'Ваш адрес электронной почты должен быть в формате name@domain.com'
            }
        },
        submitHandler: function () {
            var predata = {
                'name': f_name.val(),
                'phone': f_phone.val(),
                'email': f_email.val(),
                'message': f_message.val(),
                'type_form': $(form).attr('data-form-type')
            };
            $.ajax({
                type: "POST",
                url: "/ajax-handler/",
                data: predata,
                success: function (data, textStatus, jqXHR) {
                    var response = $.parseJSON(data);
                    if (response['status']) {
                        showPopupMsg();
                        $(form).find("input").val("");
                        $(form).trigger("reset");
                    } else {
                        showPopupMsg("", 'Ошибка отправки сообщения', "error", "OK", '#bd2d28');
                    }

                },
                error: function (data, textStatus, errorThrown) {
                    showPopupMsg("", data.responseText, "error", "OK", '#bd2d28');
                }
            });
        }
    });

    function showPopupMsg(popup_title, popup_text, popup_type, popup_btnText, popup_color) {
        swal({
            title: popup_title || "",
            text: popup_text || 'Спасибо! Ваша заявка успешно отправлена',
            type: popup_type || "success",
            confirmButtonText: popup_btnText || "OK",
            confirmButtonColor: popup_color || '#125e0e'
        });
    }
});