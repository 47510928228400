$(function() {
  function windowSizeSubMenu() {
    if ($(window).width() < "992") {
      var Accordion = function(el, child, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        // Variables privadas
        var links = this.el.find(".js-click");
        // Evento
        links.on(
          "click",
          { el: this.el, multiple: this.multiple },
          this.dropdown
        );
      };

      Accordion.prototype.dropdown = function(e) {

        if(e.target.id == 'child') return;

        var $el = e.data.el;
        ($this = $(this)), ($next = $this.next());

        $next.slideToggle();
        $this.parent().toggleClass("open");

        if (!e.data.multiple) {
          $el
            .find(".js-submenu")
            .not($next)
            .slideUp()
            .parent()
            .removeClass("open");
        }
      };

      var accordion = new Accordion($(".js-list"), false);
    }
  }

  $(window).on("resize", windowSizeSubMenu);
  windowSizeSubMenu();
});
