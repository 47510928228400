$(function () {
    $('.js-popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeBtnInside: false,
        gallery: {
            enabled: true
        }
    });
    $('.js-popup-callback').magnificPopup({
        type: 'inline',
        items: {
            src: '#callback-popup'
        },
        focus: '#name'
    });
});
