function windowSize() {
    if ($(window).width() <= '992') {
        $('.section--menu nav').appendTo($('.section--menu'));
        $('.section--menu nav').addClass('u-mb-20');
    } else if ($(window).width() >= '992') {
        $('.section--menu nav').appendTo($('.section--menu .col'));
        $('.section--menu nav').removeClass('u-mb-20');
    }
}
$(window).on('load resize', windowSize);

