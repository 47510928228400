$(function () {
var toggles = document.querySelectorAll(".js-mobile-menu-toggler");

for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
};

function toggleHandler(toggle) {
    toggle.addEventListener("click", function (e) {
        e.preventDefault();
        (this.classList.contains("active") === true) ? this.classList.remove("active") : this.classList.add("active");
    });
}
});