$(function() {
  function windowSizeBlackOut() {
    if ($(window).width() >= "992") {

      $(".menu__item").hover(function(event) {
        if ($(this).is(":has(ul.js-submenu)")) {
          $(".blackout").toggleClass("active");
        }
      });

      $(".menu__item").removeClass("open");
      $(".js-submenu").removeAttr("style");
    } else {
      $(".blackout").removeClass("active");
    }
  }

  $(window).on("resize", windowSizeBlackOut);
  windowSizeBlackOut();

  $(".blackout").hover(function() {
      $(".blackout").removeClass("active");
  });
});
