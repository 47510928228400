$(function () {
    ymaps.ready(init);
    var myMap;

    function init() {
        myMap = new ymaps.Map("map", {
            center: [57.719927, 35.813874],
            controls: [],
            zoom: 12
        });
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('drag');
        myMap.controls.add(new ymaps.control.ZoomControl());
        var myPlacemark = new ymaps.Placemark([57.719927, 35.813874],
            {
                type: "Point",
                hintContent: 'ДревРесурс',
                balloonContent: 'Тверская обл., Максатихинский р-н,<br> д. Хомутиха, здание 21Е',
                iconCaption: 'ДревРесурс'
            }, {
                iconColor: '#bd2d28'
            });


        myMap.geoObjects.add(myPlacemark);
    }
});
